import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { TokenStoreService } from '../auth/token-store.service';
import { LoginService } from './login.service';

@Component({
 styleUrls: ['./login.component.scss'],
 templateUrl: './login.component.html',
})
export class LoginComponent {
  errorMessage?: string|null;
  form = this.formBuilder.group({
    email: [null, Validators.required],
    password: [null, Validators.required]
  });

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private tokenStore: TokenStoreService) {
  }

  handleFormSubmit(): void {
    this.errorMessage = null;
    const { email, password } = this.form.value;
    this.loginService.login(email, password)
      .pipe(first())
      .subscribe({
        next: (token) => {
          this.tokenStore.storeToken(token);
          this.router.navigate(['/']);
        },
        error: (error) => {
          this.errorMessage = error.message;
        }
      });
  }
}
