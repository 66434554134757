import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface TransactionSearchResult {
  businessIdentifier: string;
  businesssName: string;
  documentName?: string;
  documentPath?: string;
  transaction: Transaction;
}

export interface Transaction {
  amount: number;
  description: string;
  date: Date;
  externalReference?: string;
}


@Injectable()
export class TransactionsService {
  constructor(private httpClient: HttpClient) {
  }

  getStatusChanges(startDate: Date, endDate: Date): Observable<TransactionSearchResult[]> {
    const params: any = { 'start-date': startDate.toISOString(), 'end-date': endDate.toISOString() };
    return this.httpClient.get<{ success: boolean, results: TransactionSearchResult[] }>('/api/transactions', { params })
      .pipe(map(f => f.results.map((a) => ({...a, transaction: {...a.transaction, date: new Date(a.transaction.date)}}))));
  }

}
