import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { DirectoryBusinessService } from './business/directory-business.service';
import { DirectoryListComponent } from './directory-list/directory-list.component';
import { NewDirectoryComponent } from './directory-list/new-directory/new-directory.component';
import { DirectoryService } from './directory.service';
import { DirectoryPermissionsService } from './permissions/directory-permissions.service';
import { DirectoryUsersService } from './users/directory-users.service';
import { DirectoryBusinessesComponent } from './view-directory/directory-businesses.component';
import { DirectoryUsersComponent } from './view-directory/directory-users.component';
import { ViewDirectoryComponent } from './view-directory/view-directory.component';

@NgModule({
  declarations: [
    DirectoryListComponent,
    NewDirectoryComponent,
    ViewDirectoryComponent,
    DirectoryUsersComponent,
    DirectoryBusinessesComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MatTableModule,
    MatButtonModule,
    MatCardModule,
    MatListModule,
    MatBottomSheetModule,
    RouterModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatAutocompleteModule,
  ],
  providers: [
    DirectoryUsersService,
    DirectoryService,
    DirectoryBusinessService,
    DirectoryPermissionsService,
  ]
})
export class DirectoryModule {

}
