import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class BusinessTemplateService {
  constructor(private httpClient: HttpClient) {
  }

  update(businessIdentifier: string, details: {template: string}): Observable<unknown> {
    return this.httpClient.put<{success: boolean}>(`/api/business/${businessIdentifier}/site-template`, details);
  }
}
