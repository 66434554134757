import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PaymentAuditRecord, DomainServiceAuditRecord, HostingServiceAuditRecord } from '../audits';
import { BusinessAuditsService } from '../business-audits.service';
import { ViewJSONComponent } from '../view-business/view-json/view-json.component';

@Component({
  template: `
    <h2>Service Logs</h2>

    <h3>Payments Made By Business</h3>
    <mat-card>
      <table *ngIf="paymentAuditRecords && paymentAuditRecords.length">
        <thead>
          <tr>
            <th>Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let record of paymentAuditRecords">
            <td>{{record.date | date: 'long' }}</td>
            <td><button mat-button color="primary" (click)="viewJsonData(record.details)">View Details</button></td>
          </tr>
        </tbody>
      </table>
    </mat-card>

    <h3>Domain messages</h3>
    <mat-card>
      <table *ngIf="domainAuditRecords && domainAuditRecords.length">
        <thead>
          <tr>
            <th>Date</th>
            <th>Service</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let record of domainAuditRecords">
            <td>{{record.date | date: 'long' }}</td>
            <td>{{record.service }}</td>
            <td><button mat-button color="primary" (click)="viewJsonData(record.details)">View Details</button></td>
          </tr>
        </tbody>
      </table>
    </mat-card>

    <h3>Hosting messages</h3>
    <mat-card>
      <table *ngIf="hostingAuditRecords && hostingAuditRecords.length">
        <thead>
          <tr>
            <th>Date</th>
            <th>Service</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let record of hostingAuditRecords">
            <td>{{record.date | date: 'long' }}</td>
            <td>{{record.service }}</td>
            <td><button mat-button color="primary" (click)="viewJsonData(record.details)">View Details</button></td>
          </tr>
        </tbody>
      </table>
    </mat-card>

  `
})
export class ServiceLogsComponent implements OnInit {
  paymentAuditRecords?: PaymentAuditRecord[];
  domainAuditRecords?: DomainServiceAuditRecord[];
  hostingAuditRecords?: HostingServiceAuditRecord[];

  constructor(
    private matDialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private auditsService: BusinessAuditsService) {
  }

  ngOnInit(): void {
    this.activatedRoute.parent?.params.subscribe((params) => {
      const identifier = params.businessIdentifier;
      this.loadAuditRecords(identifier);
    });
  }

  loadAuditRecords(businessIdentifier: string): void {
    this.auditsService.getDomainAudits(businessIdentifier)
      .subscribe((audits) => {
        this.domainAuditRecords = audits.sort((a, b) => b.date.getTime() - a.date.getTime());
      });

    this.auditsService.getPaymentAudits(businessIdentifier)
      .subscribe((audits) => {
        this.paymentAuditRecords = audits.sort((a, b) => b.date.getTime() - a.date.getTime());
      });

    this.auditsService.getHostingAudits(businessIdentifier)
      .subscribe((audits) => {
        this.hostingAuditRecords = audits.sort((a, b) => b.date.getTime() - a.date.getTime());
      });
  }

  viewJsonData(data: Object): void {
    this.matDialog.open(ViewJSONComponent, { data });
  }
}
