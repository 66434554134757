import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BusinessDirectoryRole } from '../permissions/permissions';
import { BusinessDirectoryUser } from './users';

@Injectable()
export class DirectoryUsersService {

  constructor(private httpClient: HttpClient) {}

  getUsersForDirectory(identifier: string): Observable<BusinessDirectoryUser[]> {
    return this.httpClient.get<{ success: boolean, users: BusinessDirectoryUser[]}>(`/api/directory/${identifier}/user`)
      .pipe(map(m => m.users));
  }

  addUserToDirectory(directoryIdentifier: string, userIdentifier: string, role: BusinessDirectoryRole): Observable<unknown> {
    return this.httpClient.post<{ success: boolean }>(`/api/directory/${directoryIdentifier}/user`, { userIdentifier, role });
  }

  updateUserInDirectory(directoryIdentifier: string, userIdentifier: string, role: BusinessDirectoryRole): Observable<unknown> {
    return this.httpClient.put<{ success: boolean }>(`/api/directory/${directoryIdentifier}/user/${userIdentifier}`, { role });
  }

  deleteUserFromDirectory(directoryIdentifier: string, userIdentifier: string, role: BusinessDirectoryRole): Observable<unknown> {
    return this.httpClient.delete<{ success: boolean }>(`/api/directory/${directoryIdentifier}/user/${userIdentifier}`);
  }
}
