import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth-guard';
import { BusinessListingComponent } from './business/business-listing/business-listing.component';
import { EditBusinessComponent } from './business/edit-business/edit-business.component';
import { ServiceLogsComponent } from './business/service-logs/service-logs.component';
import { ViewBusinessComponent } from './business/view-business/view-business.component';
import { DashboardComponent } from './dashboard/dashboard-component/dashboard.component';
import { DirectoryListComponent } from './directory/directory-list/directory-list.component';
import { DirectoryBusinessesComponent } from './directory/view-directory/directory-businesses.component';
import { DirectoryUsersComponent } from './directory/view-directory/directory-users.component';
import { ViewDirectoryComponent } from './directory/view-directory/view-directory.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'business',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: BusinessListingComponent,
      },
      {
        path: ':businessIdentifier',
        component: ViewBusinessComponent,
        children: [
          { path: 'service-logs', component: ServiceLogsComponent },
          { path: 'edit', component: EditBusinessComponent },
        ]
      }
    ]
  },
  {
    path: 'directory',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DirectoryListComponent,
      },
      {
        path: ':directoryIdentifier',
        component: ViewDirectoryComponent,
        children: [
          { path: 'user', component: DirectoryUsersComponent },
          { path: 'business', component: DirectoryBusinessesComponent },
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
