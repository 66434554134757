import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FTPCredentials } from './ftp-credentials';

@Component({
  styles: [`
    mat-form-field {
      width: 100%;
    }
  `],
  template: `
    <h1 mat-dialog-title>CPanel Credentials</h1>
    <div mat-dialog-content [formGroup]="form">
      <p>What is the reason?</p>
      <mat-form-field>
        <mat-label>Username</mat-label>
        <input matInput formControlName="username" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Password</mat-label>
        <input matInput formControlName="password" type="password" />
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-flat-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" (click)="handleChangeClick()" cdkFocusInitial [disabled]="!form.valid">Update</button>
    </div>
  `
})
export class FTPCredentialsModalComponent {
  reasonForChange = '';
  form = this.formBuilder.group({
    username: [],
    password: [],
  });

  constructor(
    public dialogRef: MatDialogRef<FTPCredentialsModalComponent>,
    public formBuilder: FormBuilder) {
    }

  handleChangeClick(): void {
    this.dialogRef.close({...this.form.value, port: parseInt(this.form.value.port, 10)});
  }
}
