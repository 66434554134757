import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import subWeeks from 'date-fns/subWeeks';
import { forkJoin } from 'rxjs';
import { BusinessStatus } from 'src/app/business/business';
import { StatusChangeDetail, StatusService } from '../status.service';
import { TransactionSearchResult, TransactionsService } from '../transactions.service';

@Component({
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
  statusChangeDetails: StatusChangeDetail[] = [];
  transactionSearchResults: TransactionSearchResult[] = [];
  searchRangeForm = this.formBuilder.group({
    startDate: [ subWeeks(new Date(), 1), Validators.required],
    endDate: [new Date(), Validators.required]
  });
  transactionsDisplayedColumns: string[] = ['date', 'business', 'description', 'amount', 'action'];
  statisticsData?: { name: string, value: number }[];

  constructor(
    private formBuilder: FormBuilder,
    private statusService: StatusService,
    private transactionsService: TransactionsService) {
  }

  ngOnInit() {
    this.searchForStatusChanges();
  }

  searchForStatusChanges(): void {
    const { startDate, endDate } = this.searchRangeForm.value;
    forkJoin({
      statusChanges: this.statusService.getStatusChanges(startDate, endDate),
      transactions: this.transactionsService.getStatusChanges(startDate, endDate)
    }).subscribe(({ statusChanges, transactions}) => {
      this.statusChangeDetails = statusChanges || [];
      this.statisticsData = [
        {
          name: 'Business Registrations',
          value: this.statusChangeDetails.filter(f => f.toStatus === BusinessStatus.CREATED).length
        },
        {
          name: 'Domain Registrations',
          value: this.statusChangeDetails.filter(
            f => f.fromStatus === BusinessStatus.AWAITING_DOMAIN_REGISTRATION &&
            f.toStatus === BusinessStatus.AWAITING_HOSTING_REGISTRATION).length
        },
        {
          name: 'Complete Account Activation',
          value: this.statusChangeDetails.filter(
            f => f.fromStatus === BusinessStatus.AWAITING_HOSTING_REGISTRATION &&
            f.toStatus === BusinessStatus.ACTIVE).length
        },
        {
          name: 'Total Revenue',
          value: this.transactionSearchResults.filter(f => f.transaction.amount < 0)
            .map(f => f.transaction.amount * -1)
            .reduce((a, b) => a + b, 0)
        }
      ];
      this.transactionSearchResults = transactions || [];
    });
  }
}
