import { Component, forwardRef, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { MapMarker } from 'src/app/common/map/map.component';
import { v4 as uuidv4 } from 'uuid';
import { Adddress } from '../../business';

@Component({
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => BusinessAddressComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => BusinessAddressComponent), multi: true },
  ],
  selector: 'app-edit-business-address',
  styleUrls: ['./business-address.component.scss'],
  templateUrl: './business-address.component.html',
})
export class BusinessAddressComponent implements ControlValueAccessor, Validator {
  @Input() addressNumber = 0;

  form = this.formBuilder.group({
    identifier: [uuidv4(), Validators.required],
    line1: ['', Validators.required],
    line2: [null],
    line3: [null],
    city: [null, Validators.required],
    province: [null, Validators.required],
    postalCode: [null, [Validators.required, Validators.maxLength(4), Validators.minLength(4), Validators.pattern(/^[0-9]\d*$/)]],
    location: [null]
  });

  get mapMarkers(): MapMarker[] {
    const location = this.form.controls.location.value;
    if (!location) {
      return []
    }

    return [{ coordinates: location }];
  }

  constructor(private formBuilder: FormBuilder) {}

  private onTouched = () => {};

  writeValue(obj: any): void {
    this.form.patchValue(obj || {});
  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : { address: true };
  }

  handleMapSelect(address: Adddress): void {
    this.form.patchValue({...address });
  }

  handleMapMarkerMoved(event: { longitude: number; latitude: number }): void {
    this.form.controls.location.setValue(event);
  }
}
