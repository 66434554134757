import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { FTPCredentials } from './ftp-credentials';

@Injectable()
export class FTPCredentialsService {
  constructor(private httpClient: HttpClient) {
  }

  getFTPCredentials(businessIdentifier: string): Observable<FTPCredentials|null> {
    return this.httpClient.get<{success: boolean, result: FTPCredentials}>(`/api/business/${businessIdentifier}/ftp-credentials`)
      .pipe(
        map((body) => body.result),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            return of(null);
          }

          return throwError(error);
        }),
        );
  }

  update(businessIdentifier: string, credentials: FTPCredentials): Observable<unknown> {
    return this.httpClient.post<{success: boolean}>(`/api/business/${businessIdentifier}/cpanel-credentials`, credentials);
  }
}
