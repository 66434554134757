import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ServiceTag } from '../business';
import { BusinessRetrievalService } from '../business-retrieval-service';
import { BusinessUpdateService } from '../business-status.service';
import { ServiceTagsService } from '../service-tags.service';

@Component({
  styleUrls: ['./edit-business.component.scss'],
  templateUrl: './edit-business.component.html'
})
export class EditBusinessComponent implements OnInit {
  identifier?: string;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  form = this.formBuilder.group({
    name: ['', Validators.required],
    domain: ['', Validators.required],
    description: ['', Validators.required],
    addresses: this.formBuilder.array([]),
    contactNumbers: this.formBuilder.array([new FormControl()]),
    socialProfiles: this.formBuilder.array([]),
  });

  allServices: ServiceTag[] = [];
  businessServices: ServiceTag[] = [];
  @ViewChild('servicesInput') servicesInput?: ElementRef<HTMLInputElement>;

  get addresses(): FormArray {
    return this.form.get('addresses') as FormArray;
  }

  get contactNumbers(): FormArray {
    return this.form.get('contactNumbers') as FormArray;
  }

  get socialProfiles(): FormArray {
    return this.form.get('socialProfiles') as FormArray;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private snackbar: MatSnackBar,
    private formBuilder: FormBuilder,
    private businessRetrievalService: BusinessRetrievalService,
    private businessUpdateService: BusinessUpdateService,
    private serviceTagService: ServiceTagsService) {
  }

  ngOnInit(): void {
    this.refreshServiceTags();

    this.activatedRoute.parent?.params.subscribe((params) => {
      this.identifier = params.businessIdentifier;
      if (this.identifier) {
        this.loadTagsForBusiness();
        this.businessRetrievalService.searchForBusiness(this.identifier)
          .subscribe((business) => {
            const formValues = {
              ...business,
              domain: business.domain?.secondLevelDomain
            };

            this.form.patchValue(formValues);
            this.form.controls.domain.disable();
            this.addresses.clear();
            business.addresses.forEach((address) => this.addresses.push(new FormControl(address)));

            this.contactNumbers.clear();
            business.contactNumbers.forEach((contact) => this.contactNumbers.push(new FormControl(contact)));

            this.socialProfiles.clear();
            business.socialProfiles.forEach((socialProfile) => this.socialProfiles.push(new FormControl(socialProfile)));
          });
      } else {
        this.addresses.push(new FormControl())
      }
    });
  }

  addAddress(): void {
    this.addresses.controls.push(new FormControl());
  }

  addContactNumber(): void {
    this.contactNumbers.controls.push(new FormControl());
  }

  hasSocialProfile(type: string): boolean {
    return this.socialProfiles.controls.findIndex(c => c.value?.type === type) > -1;
  }

  toggleSocialProfile(type: string): void {
    const existingIndex = this.socialProfiles.controls.findIndex(c => c.value?.type === type);
    if (existingIndex > -1) {
      this.socialProfiles.removeAt(existingIndex);
    } else {
      this.socialProfiles.push(new FormControl({ type }));
    }
  }

  addSocialProfile(type: string): void {
    this.contactNumbers.controls.push(new FormControl({ type }));
  }

  submitForm(): void {
    const formValues = this.form.value;
    const updatedIdentifier = (formValues.name as string).toLowerCase().split(' ').join('-');
    const domain: string = formValues.domain;
    const businessDetails = {
      ...formValues,
      identifier: updatedIdentifier,
      domain: {
        topLevelDomain: 'co.za',
        secondLevelDomain: domain,
      }
    };

    if (this.identifier) {
      this.businessUpdateService.updateBusiness(this.identifier, businessDetails)
        .subscribe(() => {
          this.saveTagForBusiness(updatedIdentifier);
          this.snackbar.open('Business Saved', '', { duration: 2000 });
        });
    }
  }

  refreshServiceTags(): void {
    this.serviceTagService.getAllServiceTags().pipe(first()).subscribe((tags: ServiceTag[]) => {
      this.allServices = tags;
    });
  }

  loadTagsForBusiness(): void {
    if (this.identifier) {
      this.serviceTagService.getServiceTagsForBusiness(this.identifier).pipe(first()).subscribe((tags: ServiceTag[]) => {
        this.businessServices = tags;
      });
    }
  }

  saveTagForBusiness(businesssIdentifier: string): void {
    this.serviceTagService.getServiceTagsForBusiness(businesssIdentifier).subscribe((existingTags: ServiceTag[]) => {
      const tagsToSave = this.businessServices.filter((f) => existingTags.map(t => t.name).indexOf(f.name) < 0);
      const tagsToDelete = existingTags.filter(f => this.businessServices.map(t => t.name).indexOf(f.name) < 0);
      tagsToSave.forEach((tag) => this.serviceTagService.createServiceTagForBusiness(businesssIdentifier, tag).subscribe());
      tagsToDelete.forEach((tag) => this.serviceTagService.deleteServiceTagForBusiness(businesssIdentifier, tag).subscribe());
    });
  }

  serviceSelected(event: MatAutocompleteSelectedEvent): void {
    this.addService(event.option.viewValue);
  }

  handleChipAddValue(event: MatChipInputEvent): void {
    this.addService(event.value);
  }

  addService(value: string): void {
    const tagToSave = this.allServices.find(f => f.name.toLowerCase() === value.toLowerCase()) || { name: value };

    const businessServicesIndex = this.businessServices.findIndex(f => f.name.toLowerCase() === tagToSave.name.toLowerCase());
    if (businessServicesIndex < 0) {
      this.businessServices.push(tagToSave);
    }

    this.servicesInput!!.nativeElement.value = '';
  }

  removeService(serviceTag: ServiceTag): void {
    const businessServicesIndex = this.businessServices.findIndex(f => f.name.toLowerCase() === serviceTag.name.toLowerCase());
    if (businessServicesIndex >= 0) {
      this.businessServices.splice(businessServicesIndex, 1);
    }
  }

}
