import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import * as mapboxgl from 'mapbox-gl';
import { ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Adddress } from '../../business/business';

export interface MapMarker {
  name?: string;
  identifier?: string;
  coordinates: { latitude: number, longitude: number};
}
@Component({
  selector: 'app-map',
  styles: [`
    .map-container {
      width: 100%;
      height: 100%;
      /* position: absolute; */
    }

    .bean-marker {
      background-image: url('/assets/img/logo.png');
      background-size: cover;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      cursor: pointer;
    }

    .bean-marker-image ::ng-deep {
      display: flex;
    }

    :host {
      display: block;
    }
  `
  ],
  template: `
    <div id="myMap" class="map-container"></div>
  `
})
export class MapComponent implements OnInit {
  @Input() readOnly = false;
  @Output() addressSelect: EventEmitter<Adddress> = new EventEmitter();
  @Output() markerMoved: EventEmitter<{ latitude: number, longitude: number}> = new EventEmitter();

  map?: mapboxgl.Map;
  mapMarkers: mapboxgl.Marker[] = [];
  private markerCoordinates = new ReplaySubject<MapMarker[]>(1);
  private initialCoordinatesLoaded = false;

  constructor() {
    (mapboxgl as any).accessToken = environment.mapbox.accessToken;
  }

  @Input()
  set markers(m: MapMarker[]) {
    this.markerCoordinates.next(m);
  }

  ngOnInit(): void {
    this.map = new mapboxgl.Map({
      container: 'myMap',
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 10,
      center: [28.0928086, -26.1032096]
    });
    this.map.addControl(new mapboxgl.AttributionControl());
    if (!this.readOnly) {
      this.map.addControl(new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl as any
      }).on('result', event => {
        const selectedAddress: Adddress = {
          province: event?.result?.context?.filter((f: any) => f.id?.indexOf('region') > -1)[0]?.short_code,
          city: event?.result?.context?.filter((f: any) => f.id?.indexOf('place') > -1)[0]?.text,
          line3: event?.result?.context?.filter((f: any) => f.id?.indexOf('locality') > -1)[0]?.text,
          line2: event?.result?.context?.filter((f: any) => f.id?.indexOf('neighborhood') > -1)[0]?.text,
          line1: [event?.result?.address, event?.result?.text].join(' '),
          postalCode: event?.result?.context?.filter((f: any) => f.id?.indexOf('postcode') > -1)[0]?.text,
          location: {
            latitude: event.result?.center[1],
            longitude: event.result?.center[0]
          }
        };
        this.addressSelect.emit(selectedAddress);
      }));
    }

    if (!this.readOnly) {
      this.map.on('click', (e) => {
        this.markerMoved.emit({ longitude: e.lngLat.lng, latitude: e.lngLat.lat});
      });
    }

    this.markerCoordinates.subscribe(markers => {
      if (this.map && markers) {
        this.mapMarkers.forEach(marker => marker.remove());
        this.mapMarkers = [];
        const validMarkers = markers.filter(f => f?.coordinates);
        if (this.readOnly) {
          const markersGeoJSON = {
            type: 'FeatureCollection',
            features: validMarkers.map(marker => ({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [marker.coordinates.longitude, marker.coordinates.latitude]
              },
              properties: {
                title: marker.name
              }
            }))
          };

          //        <span id="title" class="marker-title"></span>
        // <img id="marker-icon" src="assets/img/logo.png">

          markersGeoJSON.features.forEach(markerFeature => {
            // const template = document.getElementById('markerTemplate');

            const element = document.createElement('div');
            const imageElement = document.createElement('div');
            imageElement.className = 'marker-icon bean-marker-image';
            element.appendChild(imageElement);

            const titleElement = document.createElement('span');
            titleElement.className = 'marker-title';
            titleElement.innerText = markerFeature.properties.title || '';
            element.appendChild(titleElement);

            element.className = 'marker bean-marker';
            const marker = new mapboxgl.Marker(element)
              .setLngLat(markerFeature.geometry.coordinates as any)
              .setDraggable(true)
              .setPopup(new mapboxgl.Popup({ offset: 25}).setHTML(`<span>${markerFeature.properties.title || ''}</span>`))
              .addTo(this.map as any);
            this.mapMarkers.push(marker);
          });
        } else {
          this.mapMarkers.forEach(marker => marker.remove());
          this.mapMarkers = [];
          validMarkers.forEach(mapMarker => {
            const marker = new mapboxgl.Marker()
              .setLngLat([mapMarker.coordinates.longitude, mapMarker.coordinates.latitude])
              .setDraggable(true)
              .addTo(this.map as any);
            this.mapMarkers.push(marker);

            if (!this.readOnly) {
              marker.on('dragend', () => {
                this.markerMoved.emit({ longitude: marker.getLngLat().lng, latitude: marker.getLngLat().lat });
              });
            }
          });

          if (validMarkers.length === 1 && !this.initialCoordinatesLoaded) {
            this.map?.setCenter([validMarkers[0].coordinates.longitude, validMarkers[0].coordinates.latitude]);
            this.map?.setZoom(13);
            this.initialCoordinatesLoaded = true;
          }
        }
      }
    });
  }

}
