import { Component, OnInit } from '@angular/core';
import { MapMarker } from 'src/app/common/map/map.component';
import { Business } from '../business';
import { BusinessRetrievalService } from '../business-retrieval-service';

@Component({
  styleUrls: ['./business-listing.component.scss'],
  templateUrl: './business-listing.component.html'
})
export class BusinessListingComponent implements OnInit {
  businesses: Business[] = [];
  get mapMarkers(): MapMarker[] {
    return this.businesses
      .filter(f => f?.addresses?.filter(a => a.location).length)
      .map(f => ({ name: f.name, identifier: f.identifier, coordinates: f.addresses[0].location!! }));
  }

  constructor(private searchService: BusinessRetrievalService) {
  }



  ngOnInit(): void {
    this.searchService.findAllBusinesses()
      .subscribe((results) => {
        this.businesses = results;
      });
  }
}
