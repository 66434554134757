
<header>
  <h1>{{business?.name}}</h1>
</header>

<main>
  <mat-card>
    <div>
      Status: {{business?.status}}
    </div>

    <div class="action-buttons">
      <button mat-flat-button color="primary" (click)="deactivateBusiness()" *ngIf="canInactivate">Deactivate</button>
      <button mat-flat-button color="primary" (click)="suspendBusiness()" *ngIf="canSuspend">Suspend</button>
      <button mat-flat-button color="primary" (click)="activateBusiness()" *ngIf="canActivate">Activate</button>
      <button mat-flat-button color="primary" (click)="changeStatusToAwaitingPayment()" *ngIf="canChangeToAwaitingPayment">Awaiting Payment</button>
      <button mat-flat-button color="primary" (click)="resetHostingRegistration()">Reset Hosting Registration</button>
      <button mat-flat-button color="primary" (click)="completeDomainRegistration()">Complete Domain Registration</button>
      <button mat-flat-button color="accent" (click)="showUpdateFTPCredentialsPopup()">Update CPanel Credentials</button>
      <button mat-flat-button color="accent" (click)="updateBusinessTemplate('default')">Update Site Template</button>
    </div>
  </mat-card>

  <nav class="business-navigation">
    <ul>
      <li>
        <a [routerLink]="'/business/' + business?.identifier + '/edit'">Update Business</a>
      </li>
      <li>
        <a [routerLink]="'/business/' + business?.identifier + '/service-logs'">Service Logs</a>
      </li>
    </ul>
  </nav>

  <router-outlet>
  </router-outlet>
</main>
