
<div [formGroup]="form">
  <h3>Address {{addressNumber}}</h3>

  <app-map
    [markers]="mapMarkers"
    (markerMoved)="handleMapMarkerMoved($event)"
    (addressSelect)="handleMapSelect($event)"></app-map>

  <mat-form-field class="form-field">
    <mat-label>Line 1</mat-label>
    <input matInput formControlName="line1" >
  </mat-form-field>

  <mat-form-field class="form-field">
    <mat-label>Line 2</mat-label>
    <input matInput formControlName="line2" >
  </mat-form-field>

  <mat-form-field class="form-field">
    <mat-label>Line 3</mat-label>
    <input matInput formControlName="line3" >
  </mat-form-field>

  <mat-form-field class="form-field">
    <mat-label>City</mat-label>
    <input matInput formControlName="city" >
  </mat-form-field>

  <mat-form-field class="form-field">
    <mat-label>Province</mat-label>
    <select matNativeControl formControlName="province">
      <option value="ZA-EC">Eastern Cape</option>
      <option value="ZA-FS">Free State</option>
      <option value="ZA-GT">Gauteng</option>
      <option value="ZA-NL">KwaZulu-Natal</option>
      <option value="ZA-LP">Limpopo</option>
      <option value="ZA-MP">Mpumalanga</option>
      <option value="ZA-NC">Northern Cape</option>
      <option value="ZA-NW">North West</option>
      <option value="ZA-WC">Western Cape</option>
    </select>
  </mat-form-field>

  <mat-form-field class="form-field">
    <mat-label>Postal Code</mat-label>
    <input matInput formControlName="postalCode" >
    <mat-error *ngIf="form.controls.postalCode.invalid">The postal code is not valid</mat-error>
  </mat-form-field>
</div>
