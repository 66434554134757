

<header>
  <h1>Business Listing</h1>
</header>

<main>
  <mat-card>
    <mat-list role="list">
      <mat-list-item *ngFor="let directory of directories" role="listitem">
        <a [routerLink]="'/directory/' + directory.identifier">
          {{directory.name}}
        </a>
      </mat-list-item>
    </mat-list>
    <div>
      <button mat-flat-button color="primary" (click)="addNewDirectory()">Add New Directory</button>
    </div>
  </mat-card>
</main>
