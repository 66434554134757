import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DomainServiceAuditRecord, HostingServiceAuditRecord, PaymentAuditRecord } from './audits';


@Injectable()
export class BusinessAuditsService {

  constructor(private httpClient: HttpClient) {
  }

  getPaymentAudits(businessIdentifier: string): Observable<PaymentAuditRecord[]> {
    return this.httpClient.get<{ success: boolean, results: PaymentAuditRecord[]}>(`/api/business/${businessIdentifier}/audit/payment`)
     .pipe(map(r => r.results.map(a => ({ ...a, date: new Date(a.date)}))));
  }

  getHostingAudits(businessIdentifier: string): Observable<HostingServiceAuditRecord[]> {
    return this.httpClient.get<{ success: boolean, results: HostingServiceAuditRecord[]}>(`/api/business/${businessIdentifier}/audit/hosting`)
    .pipe(map(r => r.results.map(a => ({ ...a, date: new Date(a.date)}))));
  }

  getDomainAudits(businessIdentifier: string): Observable<DomainServiceAuditRecord[]> {
    return this.httpClient.get<{ success: boolean, results: DomainServiceAuditRecord[]}>(
      `/api/business/${businessIdentifier}/audit/domain`)
      .pipe(map(r => r.results.map(a => ({ ...a, date: new Date(a.date)}))));
  }
}
