import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Business } from './business';

export interface SearchParameters {
  service?: string;
  name?: string;
  domain?: {
    topLevelDomain: string;
    secondLevelDomain: string;
  }
}

@Injectable()
export class BusinessSearchService {
  constructor(private httpClient: HttpClient) {
  }

  searchForBusiness(parameters: SearchParameters): Observable<Business[]> {
    const params: any = {};

    if (parameters.domain?.secondLevelDomain && parameters.domain?.topLevelDomain) {
      params.tld = parameters.domain?.topLevelDomain;
      params.sld = parameters.domain?.secondLevelDomain;
    }

    if (parameters.name) {
      params.name = parameters.name;
    }
    return this.httpClient.get<{success: boolean, results: Business[]}>('/api/business', { params })
      .pipe(map((body) => body.results));
  }
}
