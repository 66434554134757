import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { BusinessDirectory } from '../directory';
import { DirectoryService } from '../directory.service';
import { NewDirectoryComponent } from './new-directory/new-directory.component';

@Component({
  templateUrl: './directory-list.component.html'
})
export class DirectoryListComponent implements OnInit {
  directories: BusinessDirectory[] = [];
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];

  constructor(private directoryService: DirectoryService, private matDialog: MatDialog) {
  }

  ngOnInit(): void {
    this.refreshDirectories();
  }

  addNewDirectory(): void {
    this.matDialog.open(NewDirectoryComponent).afterClosed().subscribe((details) => {
      if (!details) {
        return;
      }

      this.directoryService.addNewDirectory(details)
        .subscribe(() => this.refreshDirectories());
    });
  }

  private refreshDirectories(): void {
    this.directoryService.getDirectories().subscribe((directories) => {
      this.directories = directories;
    });
  }
}
