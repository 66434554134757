import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Business } from 'src/app/business/business';
import { BusinessDirectoryRole } from '../permissions/permissions';

@Injectable()
export class DirectoryBusinessService {

  constructor(private httpClient: HttpClient) {}

  getBusinessesForDirectory(identifier: string): Observable<Business[]> {
    return this.httpClient.get<{ success: boolean, businesses: Business[]}>(`/api/directory/${identifier}/business`)
      .pipe(map(m => m.businesses));
  }

  addBusinessToDirectory(directoryIdentifier: string, businessIdentifier: string): Observable<unknown> {
    return this.httpClient.post<{ success: boolean }>(`/api/directory/${directoryIdentifier}/business`, { businessIdentifier });
  }

  deleteBusinessFromDirectory(directoryIdentifier: string, businessIdentifier: string): Observable<unknown> {
    return this.httpClient.delete<{ success: boolean }>(`/api/directory/${directoryIdentifier}/business/${businessIdentifier}`);
  }
}
