import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BusinessDirectoryPermission, BusinessDirectoryRole } from '../permissions/permissions';

@Injectable()
export class DirectoryPermissionsService {

  constructor(private httpClient: HttpClient) {}

  getRoles(): Observable<{ role: BusinessDirectoryRole, permissions: BusinessDirectoryPermission[] }[]> {
    return this.httpClient.get<{
      success: boolean,
      roles: { role: BusinessDirectoryRole, permissions: BusinessDirectoryPermission[] }[]}>(`/api/directory-role`)
      .pipe(map(m => m.roles));
  }

  storePermissionsForRole(role: BusinessDirectoryRole, permission: BusinessDirectoryPermission): Observable<unknown> {
    return this.httpClient.post<{ success: boolean }>(`/api/directory-role/${role}`, { permission });
  }

  storePermissionsFromRole(role: BusinessDirectoryRole, permission: BusinessDirectoryPermission): Observable<unknown> {
    return this.httpClient.delete<{ success: boolean }>(`/api/directory-role/${role}/${permission}`);
  }
}
