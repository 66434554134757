import { LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';

import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LoginComponent } from './login/login.component';
import { LoginService } from './login/login.service';
import { HttpClientModule } from '@angular/common/http';
import { AuthModule } from './auth/auth.module';
import { BusinessModule } from './business/business.module';
import { StatusService } from './dashboard/status.service';
import { DashboardModule } from './dashboard/dashboard.module';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import enZALocale from '@angular/common/locales/en-ZA';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { DirectoryModule } from './directory/directory.module';

registerLocaleData(enZALocale);
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,

  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthModule,
    BrowserModule,
    CommonModule,

    MatMenuModule,
    MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,

    BusinessModule,
    DashboardModule,
    MatNativeDateModule,
    DirectoryModule,
  ],
  providers: [
    LoginService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: LOCALE_ID, useValue: 'en-ZA' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
