import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Business } from 'src/app/business/business';
import { BusinessRetrievalService } from 'src/app/business/business-retrieval-service';
import { BusinessSearchService } from 'src/app/business/business-search.service';
import { DirectoryBusinessService } from '../business/directory-business.service';
import { DirectoryUsersService } from '../users/directory-users.service';
import { BusinessDirectoryUser } from '../users/users';

@Component({
  template: `
    <div>
    <form class="example-form">
      <mat-form-field appearance="fill">
        <mat-label>Add New Business</mat-label>
        <input type="text"
              placeholder="Pick one"
              aria-label="Number"
              matInput
              [formControl]="businessNameSearch"
              [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredBusinesses|async" [value]="option.identifier" (click)="addBusiness(option.identifier)">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>

    </div>
    <mat-list role="list">
      <mat-list-item *ngFor="let business of directoryBusinesses" role="listitem">
        {{business.name}}
      </mat-list-item>
    </mat-list>
  `
})
export class DirectoryBusinessesComponent implements OnInit {
  private directoryIdentifier?: string;
  allBusinesses: Business[] = [];
  filteredBusinesses?: Observable<Business[]>;
  directoryBusinesses: Business[] = [];
  businessNameSearch = new FormControl();

  constructor(
    private activatedRoute: ActivatedRoute,
    private businessRetrievalService: BusinessRetrievalService,
    private directoryBusinessService: DirectoryBusinessService) {
  }

  addBusiness(businessIdentifier: string) {
    if (this.directoryIdentifier) {
      this.directoryBusinessService.addBusinessToDirectory(this.directoryIdentifier, businessIdentifier)
        .subscribe(() => {
          this.refreshDirectoryBusinesses();
        })
    }
  }

  ngOnInit() {
    this.activatedRoute.parent?.params.subscribe(routeParams => {
      this.directoryIdentifier = routeParams.directoryIdentifier;
      this.refreshDirectoryBusinesses()
    });

    this.businessRetrievalService.findAllBusinesses().subscribe(businesses => {
      this.allBusinesses = businesses;
    });

    this.filteredBusinesses = this.businessNameSearch.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  refreshDirectoryBusinesses() {
    if (this.directoryIdentifier) {
      this.directoryBusinessService.getBusinessesForDirectory(this.directoryIdentifier)
        .subscribe(businesses => {
          this.directoryBusinesses = businesses;
        })
    }
  }

  private _filter(value: string): Business[] {
    const filterValue = value.toLowerCase();
    return this.allBusinesses.filter(option => option.name.toLowerCase().includes(filterValue));
  }
}
