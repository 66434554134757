<div class="content">

  <mat-card>
    <form class="date-range-form" (ngSubmit)="searchForStatusChanges()">
      <mat-form-field appearance="fill">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [formGroup]="searchRangeForm" [rangePicker]="picker">
          <input matStartDate formControlName="startDate" placeholder="Start date">
          <input matEndDate formControlName="endDate" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="searchRangeForm.controls.startDate.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="searchRangeForm.controls.endDate.hasError('matEndDateInvalid')">Invalid end date</mat-error>
      </mat-form-field>

      <button type="submit" mat-flat-button color="primary" [disabled]="!searchRangeForm.valid">
        Search
      </button>
    </form>

    <div class="number-cards-container" *ngIf="statisticsData">
      <ngx-charts-number-card
        [results]="statisticsData">
      </ngx-charts-number-card>
    </div>
  </mat-card>

  <mat-card class="transactions" *ngIf="transactionSearchResults?.length">
    <h2>Transactions</h2>

    <div>
      <table mat-table [dataSource]="transactionSearchResults">
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let element">{{element.transaction.date | date:'medium'}}</td>
        </ng-container>

        <ng-container matColumnDef="business">
          <th mat-header-cell *matHeaderCellDef>Business</th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="'business/' + element.businessIdentifier">{{ element.businesssName}}</a>
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let element">{{ element.transaction.description }}</td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>Amount</th>
          <td mat-cell *matCellDef="let element">{{ element.transaction.amount }}</td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <a *ngIf="element.documentPath" target="_blank" [href]="element.documentPath" mat-icon-button color="primary" aria-label="View document">
              <mat-icon>open_in_new</mat-icon>
              {{ element.transaction.amount > 0 ? 'View Invoice' : 'View Receipt' }}
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="transactionsDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: transactionsDisplayedColumns;"></tr>
      </table>
    </div>
  </mat-card>
</div>
