import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStoreService } from './auth/token-store.service';
import { LoginService } from './login/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'bean-app-admin';
  isLoggedIn = false;
  leftMenuOpen = false;

  constructor(private tokenStore: TokenStoreService, private loginService: LoginService, private router: Router) {
    this.tokenStore.isLoggedIn.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
      if (!isLoggedIn && this.leftMenuOpen) {
        this.toggleLeftMenu();
      }
    });
  }

  logout(): void {
    this.loginService.logout().subscribe(() => {
      this.router.navigate(['/login']);
    });
  }

  toggleLeftMenu(): void {
    this.leftMenuOpen = !this.leftMenuOpen;
  }
}
