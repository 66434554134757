import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessStatus } from '../../business';

export interface ChangeStatusDialogData {
  newStatus: BusinessStatus;
}

@Component({
  styles: [`
    mat-form-field {
      width: 100%;
    }
  `],
  template: `
    <h1 mat-dialog-title>Change status to {{data.newStatus}}</h1>
    <div mat-dialog-content>
      <p>What is the reason?</p>
      <mat-form-field>
        <mat-label>Reason</mat-label>
        <input matInput required [(ngModel)]="reasonForChange" />
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-flat-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" (click)="handleChangeClick()" cdkFocusInitial [disabled]="!reasonForChange.length">Change</button>
    </div>
  `
})
export class ChangeStatusModalComponent {
  reasonForChange = '';

  constructor(
    public dialogRef: MatDialogRef<ChangeStatusModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChangeStatusDialogData) {}

  handleChangeClick(): void {
    this.dialogRef.close({
      change: true,
      reason: this.reasonForChange
    });
  }
}
