import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { MapModule } from '../common/map/map.module';
import { BusinessAuditsService } from './business-audits.service';
import { BusinessListingComponent } from './business-listing/business-listing.component';
import { BusinessRetrievalService } from './business-retrieval-service';
import { BusinessSearchService } from './business-search.service';
import { BusinessUpdateService } from './business-status.service';
import { BusinessAddressComponent } from './edit-business/business-address/business-address.component';
import { ContactNumberComponent } from './edit-business/contact-number/contact-number.component';
import { EditBusinessComponent } from './edit-business/edit-business.component';
import { SocialMediaProfileComponent } from './edit-business/social-media-profile/social-media-profile.component';
import { ServiceLogsComponent } from './service-logs/service-logs.component';
import { ServiceTagsService } from './service-tags.service';
import { ChangeStatusModalComponent } from './view-business/change-status-modal/change-status-modal.component';
import { ViewBusinessComponent } from './view-business/view-business.component';
import { PrettyJSONPipe } from './view-business/view-json/pretty-json.pipe';
import { ViewJSONComponent } from './view-business/view-json/view-json.component';
import { DomainRegistrationService } from './domain-registration.service';
import { FTPCredentialsModalComponent } from './ftp-credentials/ftp-credentials.component';
import { FTPCredentialsService } from './ftp-credentials/ftp-credentials.service';
import { BusinessTemplateService } from './business-template.service';
@NgModule({
  declarations: [
    BusinessListingComponent,
    ViewBusinessComponent,
    ServiceLogsComponent,

    EditBusinessComponent,

    ChangeStatusModalComponent,
    FTPCredentialsModalComponent,

    BusinessAddressComponent,
    ContactNumberComponent,
    SocialMediaProfileComponent,

    ViewJSONComponent,
    PrettyJSONPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatListModule,
    MatCardModule,
    RouterModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MapModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatCheckboxModule,
    MatIconModule,
    MatSnackBarModule,
  ],
  providers: [
    BusinessSearchService,
    BusinessRetrievalService,
    BusinessAuditsService,
    BusinessSearchService,
    BusinessUpdateService,
    ServiceTagsService,
    DomainRegistrationService,
    FTPCredentialsService,
    BusinessTemplateService,
  ]
})
export class BusinessModule {

}
