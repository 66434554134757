import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  styles: [`
    mat-form-field {
      width: 100%;
    }
  `],
  template: `
    <h2 mat-dialog-title>Add Directory</h2>
    <div mat-dialog-content>
      <form [formGroup]="form">
        <mat-form-field>
          <mat-label>Directory name:</mat-label>
          <input matInput type="text" required name="name" formControlName="name">
        </mat-form-field>
        <mat-checkbox formControlName="isPublic">Is Public</mat-checkbox>
      </form>
    </div>
    <div mat-dialog-actions>
      <button
        [disabled]="!form.valid"
        (click)="create()"
        type="submit" mat-flat-button color="primary">Create</button>
    </div>
  `
})
export class NewDirectoryComponent {
  form = this.formBuilder.group({
    name: ['', Validators.required],
    isPublic: [true, Validators.required]
  });

  constructor(
    private formBuilder: FormBuilder,
    private matDialogRef: MatDialogRef<NewDirectoryComponent>) {
  }

  create(): void {
    this.matDialogRef.close(this.form.value);
  }
}
