import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Business, BusinessStatus } from './business';

@Injectable()
export class BusinessUpdateService {
  constructor(private httpClient: HttpClient) {
  }

  updateBusinessStatus(businessIdentifier: string, status: BusinessStatus, comment: string): Observable<unknown> {
    return this.httpClient.put<{success: boolean}>(`/api/business/${businessIdentifier}/status`, { status, comment })
      .pipe(map((body) => body.success));
  }

  updateBusiness(identifier: string, details: Business): Observable<string> {
    return this.httpClient.put<{ success: string}>('/api/business/' + identifier, details)
      .pipe(map((response) => response.success), catchError((err: HttpErrorResponse) => {
        return throwError(err.status === 404 ? 'The business could not be found' : 'Something went wrong')
      }));
  }
}
