import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  styleUrls: ['./view-json.component.scss'],
  templateUrl: './view-json.component.html'
})
export class ViewJSONComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: unknown) {
  }
}
