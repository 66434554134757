import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Business } from './business';

export interface SearchParameters {
  service?: string;
  name?: string;
  domain?: {
    topLevelDomain: string;
    secondLevelDomain: string;
  }
}

@Injectable()
export class BusinessRetrievalService {
  constructor(private httpClient: HttpClient) {
  }

  findAllBusinesses(): Observable<Business[]> {
    return this.httpClient.get<{success: boolean, results: Business[]}>('/api/business')
      .pipe(map((body) => body.results));
  }

  searchForBusiness(identifier: string): Observable<Business> {
    return this.httpClient.get<{success: boolean, business: Business}>('/api/business/' + identifier)
      .pipe(map((body) => body.business));
  }
}
