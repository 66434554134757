import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { DomainServiceAuditRecord, HostingServiceAuditRecord, PaymentAuditRecord } from '../audits';
import { Business, BusinessStatus } from '../business';
import { BusinessAuditsService } from '../business-audits.service';
import { BusinessRetrievalService } from '../business-retrieval-service';
import { BusinessUpdateService } from '../business-status.service';
import { BusinessTemplateService } from '../business-template.service';
import { DomainRegistrationService } from '../domain-registration.service';
import { FTPCredentialsModalComponent } from '../ftp-credentials/ftp-credentials.component';
import { FTPCredentialsService } from '../ftp-credentials/ftp-credentials.service';
import { ChangeStatusModalComponent } from './change-status-modal/change-status-modal.component';
import { ViewJSONComponent } from './view-json/view-json.component';

@Component({
  styleUrls: ['./view-business.component.scss'],
 templateUrl: './view-business.component.html',
})
export class ViewBusinessComponent implements OnInit {
  business?: Business;
  paymentAuditRecords?: PaymentAuditRecord[];
  domainAuditRecords?: DomainServiceAuditRecord[];
  hostingAuditRecords?: HostingServiceAuditRecord[];

  constructor(
    private matDialog: MatDialog,
    private snackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private businessRetrievalService: BusinessRetrievalService,
    private updateService: BusinessUpdateService,
    private auditsService: BusinessAuditsService,
    private domainRegistrationService: DomainRegistrationService,
    private ftpCredentialsService: FTPCredentialsService,
    private businessTemplateService: BusinessTemplateService) {
  }

  get canInactivate(): boolean {
    return this.business?.status !== BusinessStatus.INACTIVE;
  }

  get canActivate(): boolean {
    return this.business?.status !== BusinessStatus.ACTIVE;
  }

  get canSuspend(): boolean {
    return this.business?.status !== BusinessStatus.SUSPENDED;
  }

  get canChangeToAwaitingPayment(): boolean {
    return this.business?.status === BusinessStatus.ACTIVE;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      const identifier = params.businessIdentifier;
      this.loadBusiness(identifier);

    });
  }

  loadBusiness(identifier: string): void {
    this.loadAuditRecords(identifier);
    this.businessRetrievalService.searchForBusiness(identifier)
      .subscribe((business) => {
        this.business = business;
      });
  }

  loadAuditRecords(businessIdentifier: string): void {
    this.auditsService.getDomainAudits(businessIdentifier)
      .subscribe((audits) => {
        this.domainAuditRecords = audits.sort((a, b) => b.date.getTime() - a.date.getTime());
      });

    this.auditsService.getPaymentAudits(businessIdentifier)
      .subscribe((audits) => {
        this.paymentAuditRecords = audits.sort((a, b) => b.date.getTime() - a.date.getTime());
      });

    this.auditsService.getHostingAudits(businessIdentifier)
      .subscribe((audits) => {
        this.hostingAuditRecords = audits.sort((a, b) => b.date.getTime() - a.date.getTime());
      });
  }

  viewJsonData(data: Object): void {
    this.matDialog.open(ViewJSONComponent, { data });
  }

  deactivateBusiness(): void {
    this.showUpdatePopup(BusinessStatus.INACTIVE);
  }

  suspendBusiness(): void {
    this.showUpdatePopup(BusinessStatus.SUSPENDED);
  }

  activateBusiness(): void {
    this.showUpdatePopup(BusinessStatus.ACTIVE);
  }

  resetHostingRegistration(): void {
    this.showUpdatePopup(BusinessStatus.AWAITING_HOSTING_REGISTRATION);
  }

  completeDomainRegistration(): void {
    if (this.business) {
      this.domainRegistrationService.completeDomainRegistration(this.business?.identifier)
        .subscribe(() => {
          if (this.business) {
            this.loadAuditRecords(this.business?.identifier);
          }
        });
    }
  }

  changeStatusToAwaitingPayment(): void {
    this.showUpdatePopup(BusinessStatus.AWAITING_PAYMENT);
  }

  updateStatus(status: BusinessStatus, reason: string): void {
    if (!this.business) {
      return;
    }

    this.updateService.updateBusinessStatus(this.business.identifier, status, reason)
      .subscribe(() => {
        if (!this.business) {
          return;
        }

        this.loadBusiness(this.business?.identifier);
      });
  }

  showUpdatePopup(status: BusinessStatus): void {
    if (!this.business) {
      return;
    }

    const dialog = this.matDialog.open(ChangeStatusModalComponent, {
      data: {
        newStatus: status
      }
    });

    dialog.afterClosed().subscribe(result => {
      if (result?.change) {
        this.updateStatus(status, result.reason);
      }
    });
  }

  showUpdateFTPCredentialsPopup(): void {
    if (!this.business) {
      return;
    }

    const dialog = this.matDialog.open(FTPCredentialsModalComponent);

    dialog.afterClosed().subscribe(updatedCredentials => {
      if (updatedCredentials && this.business) {
        this.ftpCredentialsService.update(this.business?.identifier, updatedCredentials).subscribe();
      }
    });
  }

  updateBusinessTemplate(template: string): void {
    if (!this.business) {
      return;
    }

    this.businessTemplateService.update(this.business.identifier, {template}).subscribe(() => {
      this.snackbar.open('Successfully updated');
    });
  }

}
