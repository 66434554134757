import { NgModule } from '@angular/core';
import { MapComponent } from './map.component';

@NgModule({
  declarations: [
    MapComponent
  ],
  exports: [
    MapComponent
  ]
})
export class MapModule {

}
