
<header>
  <h1>Login</h1>
</header>

<main>
  <mat-card class="content">
    <form [formGroup]="form" (ngSubmit)="handleFormSubmit()">
      <mat-form-field>
        <mat-label>Email:</mat-label>
        <input matInput required formControlName="email">
      </mat-form-field>


      <mat-form-field>
        <mat-label>Password:</mat-label>
        <input type="password" matInput required formControlName="password">
      </mat-form-field>

      <div>
        <button type="submit" mat-raised-button color="primary">Login</button>
      </div>
    </form>
  </mat-card>
</main>
