import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BusinessDirectory } from './directory';

@Injectable()
export class DirectoryService {

  constructor(private httpClient: HttpClient) {}

  getDirectory(identifier: string): Observable<BusinessDirectory> {
    return this.httpClient.get<{ success: boolean, directory: BusinessDirectory}>(`/api/directory/${identifier}`)
      .pipe(map(m => m.directory));
  }

  getDirectories(): Observable<BusinessDirectory[]> {
    return this.httpClient.get<{ success: boolean, directories: BusinessDirectory[]}>(`/api/directory`)
      .pipe(map(m => m.directories));
  }

  addNewDirectory(directory: BusinessDirectory): Observable<unknown> {
    return this.httpClient.post<{ success: boolean }>(`/api/directory`, directory);
  }

  updateDirectory(directoryIdentifier: string, directory: BusinessDirectory): Observable<unknown> {
    return this.httpClient.put<{ success: boolean }>(`/api/directory/${directoryIdentifier}`, directory);
  }
}
