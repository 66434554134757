import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceTag } from './business';

@Injectable({ providedIn: 'root' })
export class DomainRegistrationService {
  constructor(private httpClient: HttpClient) {
  }

  completeDomainRegistration(businessIdentifier: string): Observable<unknown> {
    return this.httpClient.put<{ success: boolean}>(`/api/business/${businessIdentifier}/domain-registration`, {});
  }
}
