

<header>
  <h1>Business Listing</h1>
</header>

<main>
  <mat-card>
    <app-map [markers]="mapMarkers" [readOnly]="true"></app-map>

    <mat-list role="list">
      <mat-list-item *ngFor="let business of businesses" role="listitem">
        <a [routerLink]="'/business/' + business.identifier">
          {{business.name}}
        </a>

      </mat-list-item>
    </mat-list>
  </mat-card>
</main>
