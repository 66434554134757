import { Component, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';

@Component({
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SocialMediaProfileComponent), multi: true},
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => SocialMediaProfileComponent), multi: true},
  ],
  selector: 'app-social-media-profile',
  styleUrls: ['./social-media-profile.component.scss'],
  templateUrl: './social-media-profile.component.html',
})
export class SocialMediaProfileComponent implements ControlValueAccessor, Validator {

  form = this.formBuilder.group({
    type: [null, Validators.required],
    path: [null, Validators.required]
  });

  constructor(private formBuilder: FormBuilder) {
  }

  writeValue(obj: any): void {
    this.form.patchValue(obj || {});
  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }

    return {
      invalidSocialProfile: 'The social profile is not valid',
    };
  }

  getSocialDescription(): string {
    const type = this.form.controls.type.value as string;
    const descriptions: { [key: string]: string } = {
      FACEBOOK: 'Facebook',
      INSTAGRAM: 'Instagram',
      TWITTER: 'Twitter'
    };

    return descriptions[type];
  }

}
