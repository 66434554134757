import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BusinessDirectory } from '../directory';
import { DirectoryService } from '../directory.service';

@Component({
  styles: [`
    nav ul li {
      display: inline;
      margin-right: 1em;
    }
  `],
  template: `
    <header><h1>{{directory?.name}}</h1></header>

    <nav>
      <ul>
        <li><a [routerLink]="'/directory/' + identifier + '/business'">Businesses</a></li>
        <li><a [routerLink]="'/directory/' + identifier + '/user'">Users</a></li>
      </ul>
    </nav>

    <main>
      <mat-card>
        <router-outlet></router-outlet>
      </mat-card>
    </main>
  `
})
export class ViewDirectoryComponent implements OnInit {
  identifier?: string;
  directory?: BusinessDirectory;

  constructor(private activatedRoute: ActivatedRoute, private directoryService: DirectoryService
    ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.identifier = params.directoryIdentifier;
      if (this.identifier) {
        this.directoryService.getDirectory(this.identifier)
          .subscribe((directory) => {
            this.directory = directory;
          })
      }
    });
  }
}
