import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BusinessStatus } from '../business/business';

export interface StatusChangeDetail {
  businessIdentifier: string;
  businessName: string;
  fromStatus?: BusinessStatus;
  toStatus: BusinessStatus;
  date: Date;
  comment?: string;
}

@Injectable()
export class StatusService {
  constructor(private httpClient: HttpClient) {
  }

  getStatusChanges(startDate: Date, endDate: Date): Observable<StatusChangeDetail[]> {
    const params: any = { 'start-date': startDate.toISOString(), 'end-date': endDate.toISOString() };
    return this.httpClient.get<{ success: boolean, results: StatusChangeDetail[] }>('/api/business-status', { params })
      .pipe(map(f => f.results.map((a) => ({...a, date: new Date(a.date)}))));
  }

}
