
<mat-toolbar class="mat-elevation-z2">
  <button *ngIf="isLoggedIn" mat-icon-button class="left-menu-button" aria-label="Menu button" (click)="toggleLeftMenu()">
    <mat-icon>menu</mat-icon>
  </button>

  <a class="toolbar-title" [routerLink]="'/'">
    <img class="toolbar-logo" src="assets/img/logo.png" >
    The Bean App Admin
  </a>
  <button class="toolbar-nav-button" color="primary" *ngIf="isLoggedIn" mat-button [routerLink]="'/dashboard'">
    Dashboard
  </button>

  <button class="toolbar-nav-button" color="primary" *ngIf="isLoggedIn" mat-button [routerLink]="'/business'">
    Businesses
  </button>

  <button class="toolbar-nav-button" color="primary" *ngIf="isLoggedIn" mat-button [routerLink]="'/directory'">
    Directories
  </button>

  <span class="spacer"></span>

  <button class="login-button toolbar-nav-button" color="accent" *ngIf="!isLoggedIn" mat-flat-button [routerLink]="'/login'">
    Login
  </button>

  <button class="logout-button toolbar-nav-button"  *ngIf="isLoggedIn" mat-button (click)="logout()">
    Logout
  </button>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav mode="side" [opened]="leftMenuOpen">
    <ul class="side-navigation-list">
      <li class="side-navigation-list-item">
        <a [routerLink]="'/business'">Businesses</a>
      </li>
    </ul>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet>
    </router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>


