import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isValidPhoneNumber } from 'libphonenumber-js';

export function phoneNumber(control: AbstractControl): ValidationErrors | null {
  const value = control.value;
  if (!value) {
    return null;
  }

  return isValidPhoneNumber(control.value, 'ZA') ? null : { phoneNumber: true };
}
